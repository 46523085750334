import Sidebar from '../../components/Common/Sidebar';
import { LenderIqList } from '../../components/_pages/LenderIq/LenderIqList';

const LenderIq = () => {
  return (
    <>
      <Sidebar />
      <LenderIqList />
    </>
  );
};

export default LenderIq;
