import {getForms, getLenderIqForm} from "../../../services/forms.services";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IForms} from "../../../types/forms";
import ReactPagination from "../../Common/ReactPagination";
import Loading from "../../Common/LoadingScreen";
import Moment from "moment";

import Modal from "react-modal";
import {jwtDecode} from "jwt-decode";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale.css";
import downloadAsFiles from "../../../services/download.services";
import deleteLenderDataByID from "../../../services/lenderDelete.service";

export const LenderIqList = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [lenderIqData, setLenderIqData] = useState<any>();
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffSet] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const [selectedData, setSelectedData] = useState<any>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);

  const [deleteDataByIDModal, setDeleteDataByIDModal] = useState(false);
  const [dataID, setDataID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const handleModal = () => setDeleteDataByIDModal(!deleteDataByIDModal);

  const deleteValue = (id: any, firstName: any, lastName: any) => {
    setFirstName(firstName);
    setLastName(lastName);
    setDataID(id);
  };

  useEffect(() => {
    getContacts();
  }, [offset, limit, search]);

  const tokenInfo: any = jwtDecode(localStorage.getItem("access_token") as string);

  const handleDownload = async () => {
    setButtonDisable(true);
    const fileData = await downloadAsFiles("formTypeId=19");
    // const blob = new Blob([fileData.data], { type: 'text/csv;charset=utf-8;' });

    const selectedFileData = await downloadAsFiles(
      `formTypeId=19${selectedData.map((item: any) => `&selectedId=${item.id}`).join("")}`
    );

    let blob: any;
    if (selectedData) {
      blob = new Blob([selectedFileData.data], {
        type: "text/csv;charset=utf-8;",
      });
    } else {
      blob = new Blob([fileData.data], {type: "text/csv;charset=utf-8;"});
    }

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "lenderIq.csv");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    setButtonDisable(false);

    getContacts();
  };

  const getContacts = async () => {
    const getFormsArgs = {
      formTypeId: 19,
      limit: limit,
      offset: offset,
      name: search,
    };
    try {
      const lenderIqData = await getLenderIqForm(getFormsArgs);

      setLenderIqData(lenderIqData.data);
      setIsLoading(false);
    } catch (e: any) {
      if (!e.statusCode) {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    // console.log('contacts: ', contacts?.data);

    const includes = lenderIqData?.data.every((contact: any) => {
      return selectedData?.some((selected: any) => selected.id === contact.id);
    });
    // console.log('includes: ', includes);
    includes ? setIsSelectAllChecked(true) : setIsSelectAllChecked(false);
  }, [lenderIqData?.data]);

  const handleInput = (event: any) => {
    setSearch(event.target.value);
  };

  const handleDelete = async (id: any) => {
    try {
      deleteLenderDataByID(id);
      handleModal();
      getContacts();
    } catch (e: any) {
      if (!e.statusCode) {
        localStorage.removeItem("access_token");
        navigate("/");
      }
    }
  };

  const handleChange = (e: any) => {
    const {id, checked} = e.target;
    // console.log('id: ', id);
    // console.log('checked: ', checked);
    let filteredCheckedData: any = [];
    let allCheckedData: any = [];

    if (id === "selectAll") {
      // console.log('Entered into selectedAll zone..');

      if (
        checked &&
        !lenderIqData?.data.every((contact: any) => {
          return selectedData?.some((selected: any) => selected.id === contact.id);
        })
      ) {
        allCheckedData = [
          ...selectedData,
          ...lenderIqData?.data.map((contact: any) => ({
            ...contact,
            // checked: true,
          })),
        ];
      } else if (
        !checked &&
        lenderIqData?.data.every((contact: any) => {
          return selectedData?.some((selected: any) => selected.id === contact.id);
        })
      ) {
        allCheckedData = selectedData?.filter(
          (data: any) =>
            !lenderIqData?.data.some((contact: any) => contact.id === data.id && contact.name === data.name)
        );
      }

      if (allCheckedData.length > 0) {
        filteredCheckedData = allCheckedData?.filter(
          (dataItem: any, index: any, self: any) =>
            index === self.findIndex((i: any) => i.id === dataItem.id && i.name === dataItem.name)
        );
        setSelectedData(filteredCheckedData);
      } else {
        setSelectedData(allCheckedData);
      }
      setIsSelectAllChecked(checked);
    } else {
      // console.log('Entered into plane zone..');
      // checked ? console.log('yes') : console.log('nope');

      const updatedSelectedData = checked
        ? [...selectedData, lenderIqData?.data.find((contact: any) => contact.id === Number(id))]
        : selectedData?.filter((contact: any) => contact.id !== Number(id));

      // console.log('Updated Selected Data', updatedSelectedData);

      setSelectedData(updatedSelectedData);

      lenderIqData?.data.every((contact: any) => {
        return updatedSelectedData?.some((element: any) => element.id === contact.id);
      })
        ? setIsSelectAllChecked(true)
        : setIsSelectAllChecked(false);
    }
  };

  return (
    <>
      {/* <section className="main-container">
        <div className="top-section align-items-center row">
          <div className="col-md-9">
            <div className="xlarge-text-size">Contact</div>
          </div>
          <div className="col-md-3 col-xl-2 text-right">
            <input
              value={search}
              onChange={handleInput}
              placeholder="Search by name"
            />
          </div>
          <div className="col-md-2 text-right">
            <button
              type="button"
              className="btn"
              onClick={handleDownload}
              disabled={buttonDisable}
            >
              Download{" "}
              {selectedData?.length === 0 ? "all" : selectedData?.length}
            </button>
          </div>
        </div> */}

      <section className="main-container">
        <div className="top-section align-items-center row">
          <div className="col-md-7 col-xl-8">
            <div className="xlarge-text-size">Lender IQ</div>
          </div>
          <div className="col-md-3 col-xl-2 text-right">
            <input value={search} onChange={handleInput} placeholder="Search by name" />
          </div>
          <div className="col-md-2 text-right">
            <button type="button" className="btn" onClick={handleDownload} disabled={buttonDisable}>
              Download {selectedData?.length === 0 ? "all" : selectedData?.length}
            </button>
          </div>
        </div>

        {/* main data */}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              {isLoading && <Loading />}
              <table className="table">
                <thead>
                  <tr key="title">
                    <th className="xs">
                      <input
                        type="checkbox"
                        id="selectAll"
                        // onChange={handleChange}
                        checked={isSelectAllChecked}
                      />
                    </th>
                    <th className="xs">S.No.</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Contact Number</th>
                    <th className="lg">Page Url</th>
                    <th className="sm">Source</th>
                    <th className="sm">Medium</th>
                    <th className="sm">Content</th>
                    <th className="sm">Campaign</th>
                    <th>Submitted on</th>
                    {tokenInfo.permissions[0] === "Delete" ? <th className="xs">Action</th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {lenderIqData?.data === ""
                    ? "Empty data"
                    : lenderIqData?.data.map((lenderIq: IForms, index: any) => {
                        return (
                          <tr key={lenderIq.id}>
                            <td>
                              <input
                                type="checkbox"
                                id={JSON.stringify(lenderIq.id)}
                                checked={selectedData?.some((item: any) => item.id === lenderIq.id)}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>{index + offset + 1}</span>
                            </td>
                            <td>
                              {lenderIq.firstName !== "." ? (
                                <>
                                  <span>{lenderIq.firstName}</span> <span>{lenderIq.lastName}</span>
                                </>
                              ) : (
                                <span>{lenderIq.fullName}</span>
                              )}
                            </td>
                            <td>
                              <span>{lenderIq.email ?? "-"}</span>
                            </td>
                            <td>
                              <span>{lenderIq.contactNumber ?? "-"}</span>
                            </td>
                            <td className="sm">
                              <span>{lenderIq.pageUrl ?? "-"}</span>
                            </td>
                            <td className="sm">
                              <span>{lenderIq.utmSource ?? "-"}</span>
                            </td>
                            <td className="sm">
                              <span>{lenderIq.utmMedium ?? "-"}</span>
                            </td>
                            <td className="sm">
                              <span>{lenderIq.utmContent ?? "-"}</span>
                            </td>
                            <td className="sm">
                              <span>{lenderIq.utmCampaign ?? "-"}</span>
                            </td>
                            <td>
                              <span className="">
                                {Moment(lenderIq.createdAt).format("ddd, MMM Do YYYY, h:mm:ss a")}
                              </span>
                              <span className="hide">{lenderIq.createdBy}</span>
                            </td>

                            {tokenInfo.permissions[0] === "Delete" ? (
                              <>
                                <td className="xs">
                                  <button
                                    className="btn-danger btn"
                                    type="button"
                                    onClick={() => {
                                      handleModal();
                                      deleteValue(lenderIq.id, lenderIq.lastName, lenderIq.firstName);
                                    }}>
                                    Delete
                                  </button>
                                </td>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
            <div className="pagination-log">
              <ReactPagination totalData={lenderIqData} setLimit={setLimit} setOffSet={setOffSet} limit={limit} />
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={deleteDataByIDModal} onRequestClose={handleModal} contentLabel="delete-data">
        <div className="head">
          <h4>Remove data</h4>
        </div>
        <div className="m-body">
          <div>
            Are you sure you want to remove this{" "}
            <b>
              {firstName} {lastName}
            </b>{" "}
            data
          </div>
        </div>
        <div className="m-footer">
          <button className="btn btn-link" onClick={handleModal}>
            Close
          </button>{" "}
          {"  "}
          <button className="btn btn-danger" onClick={() => handleDelete(dataID)}>
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};
