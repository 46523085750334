import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import removeAuthToken from "../../services/removeAuthHeader";
import { useNavigate, useLocation } from "react-router-dom";
import Logout from "../_pages/_Logout/Logout";

const Sidebar = () => {
  const sideMenu = [
    { id: 1, text: "Case Study", url: "/case-study" },
    { id: 2, text: "Download Syllabus", url: "/syllabus-download" },
    { id: 3, text: "Book a demo", url: "/book-a-demo" },
    { id: 4, text: "EBook", url: "/ebook" },
    { id: 5, text: "Whitepaper", url: "/whitepaper" },
    // {id: 6, text: "AI Assessment", url: "/ai-assessment"},
    { id: 7, text: "Subscribe", url: "/subscribe" },
    { id: 8, text: "Event", url: "/event" },
    { id: 9, text: "Contact", url: "/contacts" },
    { id: 10, text: "Assets", url: "/assets" },
    { id: 11, text: "Podcasts", url: "/podcasts" },
    { id: 12, text: "Lender IQ", url: "/lender-iq" },

  ];

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <aside>
      <div className="logo">
        <img src="/images/logo.svg" alt="logo" />
      </div>
      <ul className="nav">
        {sideMenu.map((val) => (
          <li key={val.id}>
            <a
              className={`link ${
                location.pathname === val.url ? "active" : ""
              }`}
              onClick={() => navigate(val.url)}
            >
              <span>{val.text}</span>
            </a>
          </li>
        ))}
      </ul>
      <div className="logout">
        <Logout />
      </div>
    </aside>
  );
};

export default Sidebar;
